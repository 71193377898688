import Client from '@/repositories/AxiosClient';

const resource = '/storage';

export default {
    list() {
        return Client().get(`${resource}/`)
    },
    listForStudio(studio_id) {
        return Client().get(`${resource}/${studio_id}`)
    },
    get(studio_id, label) {
        return Client().get(`${resource}/${studio_id}/${label}`)
    },
    getMetrics(studio_id, label) {
        return Client().get(`${resource}/${studio_id}/${label}/metrics`)
    },
    delete(studio_id, label) {
        return Client().delete(`${resource}/${studio_id}/${label}`)
    },
    getFsxStatus(studio_id, label) {
        return Client().get(`${resource}/${studio_id}/${label}/fsx`)
    },
    editFsx(studio_id, label, payload) {
        return Client().put(`${resource}/${studio_id}/${label}/fsx`, payload)
    },
    getLastFsxConfig(studio_id, label) {
        return Client().get(`${resource}/${studio_id}/${label}/fsx/last-config`)
    },
    getFsxDrtStatus(studio_id, label) {
        return Client().get(`${resource}/${studio_id}/${label}/fsx/drt`)
    },
    runDrtNow(studio_id, label, paths) {
        return Client().post(`${resource}/${studio_id}/${label}/fsx/drt`, {completion_report: true, paths: paths})
    },
    create(studio_id, payload) {
        return Client().post(`${resource}/${studio_id}`, payload)
    },
    refreshAutomount(studio_id) {
        return Client().post(`${resource}/${studio_id}/refresh`, {})
    },
    createFsx(studio_id, label, payload) {
        return Client().post(`${resource}/${studio_id}/${label}/fsx`, payload)
    },
    deleteFsx(studio_id, label, data) {
        return Client().delete(`${resource}/${studio_id}/${label}/fsx`, {data})
    },
    update(studio_id, label, payload) {
        return Client().put(`${resource}/${studio_id}/${label}`, payload)
    },
    getWarmerRuns(studio_id, label, start) {
        const query = start? `start=${start}` : ""
        return Client().get(`${resource}/${studio_id}/${label}/fsx/warmer?${query}`)
    },
    updateWarmingRules(studio_id, label, data) {
        return Client().put(`${resource}/${studio_id}/${label}/fsx/warmer-rule`, data)
    },
    runWarmingTask(studio_id, label, data) {
        return Client().put(`${resource}/${studio_id}/${label}/fsx/warmer`, data)
    },
    getAllAutomatorConfigs(studio_id, label) {
        return Client().get(`${resource}/${studio_id}/${label}/fsx/automator`)
    },
    getAutomatorConfig(studio_id, label, ruleId) {
        return Client().get(`${resource}/${studio_id}/${label}/fsx/automator/${ruleId}`)
    },
    createAutomatorConfig(studio_id, label, data) {
        return Client().post(`${resource}/${studio_id}/${label}/fsx/automator`, data)
    },
    updateAutomatorConfig(studio_id, label, id, data) {
        return Client().put(`${resource}/${studio_id}/${label}/fsx/automator/${id}`, data)
    },
    setAutomatorConfigEnabled(studio_id, label, id, enabled) {
        return Client().patch(`${resource}/${studio_id}/${label}/fsx/automator/${id}`, {enabled})
    },
    deleteAutomatorConfig(studio_id, label, id) {
        return Client().delete(`${resource}/${studio_id}/${label}/fsx/automator/${id}`)
    },
};
